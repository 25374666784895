<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <a-card>
                <a-row type="flex" :gutter="[8, 32]">
                    <a-col>
                        <a-tree-select
                            style="width: 200px"
                            tree-default-expand-all
                            multiple
                            allowClear
                            :placeholder="l('Company')"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="companyTreeList"
                            v-model="companyId"
                            @change="refresh"
                        />
                    </a-col>
                    <a-col>
                        <a-button type="primary" @click="Export()" v-if="isGranted('sl_baseinfo_excel')">
                            <a-icon type="download" />
                            <span>导出</span>
                        </a-button>
                    </a-col>
                </a-row>
                <a-row :gutter="[8, 32]">
                    <a-col>
                        <a-table
                            id="table"
                            :columns="columns"
                            :scroll="{ y: scroll_y }"
                            :pagination="false"
                            bordered
                            size="small"
                            :dataSource="tableData"
                        >
                        </a-table>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-pagination
                            class="pagination"
                            :total="totalItems"
                            v-model="pageNumber"
                            showSizeChanger
                            showQuickJumper
                            :showTotal="showTotalFun"
                            @change="onChangePage"
                            @showSizeChange="showSizeChange"
                        />
                    </a-col>
                </a-row>
            </a-card>
        </a-spin>
    </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import { CompanyServiceProxy, CustomizeReportServiceServiceProxy } from '../../../../shared/service-proxies';
import { fileDownloadService } from '../../../../shared/utils';
import { AppComponentBase } from '../../../../shared/component-base';

export default {
    name: 'emp-educations-report',
    mixins: [AppComponentBase],
    data() {
        return {
            zh_CN,
            companyTreeList: [],
            companyId: undefined,
            //表格上方间隔
            defaultTop: 60,
            columns: [
                {
                    title: '集团',
                    dataIndex: 'parentCompany',
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: '公司',
                    dataIndex: 'company',
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: '部门',
                    dataIndex: 'department',
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: '初中及以下',
                    children: [
                        {
                            title: '人数',
                            dataIndex: 'educations1_Number',
                            ellipsis: true,
                            align: 'center',
                        },
                        {
                            title: '比例',
                            dataIndex: 'educations1_Prop',
                            ellipsis: true,
                            align: 'center',
                        },
                    ],
                },
                {
                    title: '高中/中专',
                    children: [
                        {
                            title: '人数',
                            dataIndex: 'educations2_Number',
                            align: 'center',
                        },
                        {
                            title: '比例',
                            dataIndex: 'educations2_Prop',
                            align: 'center',
                        },
                    ],
                },
                {
                    title: '专科',
                    children: [
                        {
                            title: '人数',
                            dataIndex: 'educations3_Number',
                            align: 'center',
                        },
                        {
                            title: '比例',
                            dataIndex: 'educations3_Prop',
                            align: 'center',
                        },
                    ],
                },
                {
                    title: '本科',
                    children: [
                        {
                            title: '人数',
                            dataIndex: 'educations4_Number',
                            align: 'center',
                        },
                        {
                            title: '比例',
                            dataIndex: 'educations4_Prop',
                            align: 'center',
                        },
                    ],
                },
                {
                    title: '硕士/博士',
                    children: [
                        {
                            title: '人数',
                            dataIndex: 'educations5_Number',
                            align: 'center',
                        },
                        {
                            title: '比例',
                            dataIndex: 'educations5_Prop',
                            align: 'center',
                        },
                    ],
                },
            ],
            tableData: [],
            totalItems: 0, //总数
            // 当前页码
            pageNumber: 1,
            // 共多少页
            totalPages: 1,
            // 条数显示范围
            pagerange: [1, 1],
            // 显示条数
            pageSizeOptions: ['10', '20', '30', '40'],
            request: { maxResultCount: 10, skipCount: 0, sorting: '' },
        };
    },
    created() {
        this._companyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
        this._customizeReportServiceServiceProxy = new CustomizeReportServiceServiceProxy(this.$apiUrl, this.$api);
        this._fileDownloadService = fileDownloadService;
    },
    mounted() {
        this.companyTreeInit();
        this.getData();
    },
    methods: {
        refresh() {
            this.pageNumber = 1;
            this.request.skipCount = 0;
            this.getData();
        },
        getData() {
            this.loading = true;
            this._customizeReportServiceServiceProxy
                .getEmpEducationsReport(
                    this.companyId,
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.tableData = res.items;
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                });
        },
        companyTreeInit() {
            this.loading = true;
            this._companyConfigServiceProxy
                .getPaged(undefined, '', 100, 0)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.companyList = res.items;
                    this.companyList.forEach((item) => {
                        if (item.parentId == 0) {
                            var obj = {
                                key: item.id,
                                value: item.id,
                                title: item.fullName,
                            };
                            this.companyTreeList.push(obj);
                        }
                    });
                    this.initCompanyTree(this.companyTreeList);
                });
        },
        /**
         * 初始化树形结构数据格式
         */
        initCompanyTree(list) {
            list.forEach((v) => {
                //遍历父级数据，加载子集=
                v.children = []; //创建空的子集
                this.companyList.forEach((m) => {
                    if (m.parentId == v.value) {
                        //根据主键查子集数据
                        var obj = {
                            key: m.id,
                            value: m.id,
                            title: m.fullName,
                        };
                        v.children.push(obj); //放进父级的集合中
                        this.initCompanyTree(v.children); //递归查询子集的集合
                    }
                });
                if (v.children.length == 0) {
                    //校验如何数据没有子集，删除子集参数
                    delete v.children;
                }
            });
        },
        /**
         * 分页事件
         */
        showTotalFun() {
            return this.l(
                'GridFooterDisplayText',
                this.pageNumber,
                this.totalPages,
                this.totalItems,
                this.pagerange[0],
                this.pagerange[1]
            );
        },
        /**
         * 分页
         */
        onChangePage(page, pageSize) {
            this.pageNumber = page;
            this.request.skipCount = (page - 1) * this.request.maxResultCount;
            this.getData();
        },
        showSizeChange(current, size) {
            // this.pageNumber = 1;
            this.request.maxResultCount = size;
            this.refresh();
        },
        Export() {
            if (this.tableData.length <= 0) {
                return abp.message(this.l('NoData'));
            }
            this.loading = true;
            this._customizeReportServiceServiceProxy
                .getEmpEducationsExport(
                    this.companyId,
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this._fileDownloadService.downloadTempFile(res);
                });
        },
    },
};
</script>

<style scoped>
.pagination {
    margin: 10px auto;
    text-align: right;
}
</style>
